:root {
  --theme-color: #2d3838;
  --white-color: #ffffff;
  --disable-btn-color: #2d383852;
  --border-color: #909696;
  --light-theme-color: #f1e7d5;
}
.appbar-logo {
  max-width: 110px;
}
.navigation-menu .MuiList-root {
  padding: 0px !important;
  width: 300px !important;
}
.navigation-menu .MuiList-root .MuiMenuItem-root {
  border-bottom: 1px solid var(--border-color) !important;
  padding: 10px 20px !important;
}
.navigation-menu .MuiList-root .MuiMenuItem-root:last-child {
  border-bottom: none !important;
}
.navigation-menu .MuiMenuItem-root.active {
  background-color: var(--light-theme-color) !important;  
}
.navigation-menu .MuiMenuItem-root:hover {
  background-color: var(--light-theme-color) !important;
}
.MuiMenuItem-root .MuiListItemIcon-root {
  color: var(--theme-color) !important;
}
.MuiMenuItem-root .MuiTypography-root {
  color: var(--theme-color) !important;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif !important;
  letter-spacing: unset !important;
}
.MuiMenuItem-root.active .MuiTypography-root,
.MuiMenuItem-root.active .MuiListItemIcon-root {
  color: var(--theme-color) !important;
}
